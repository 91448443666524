/* You can add global styles to this file, and also import other style files */
$overlayZindex: 900;
$mapOverlayUiIndex: 800;
$mapOverlayZindex: 700;
$headerZindex: 500;
$chatComponentIndex: 400;
$content: 100;

$appBackgroundColor: lightgray;
$sideNavBackgroundColor: lightgray;

$pageBackgroundColor: #eaf3f5;

//LAYOUT
$headerHeight: 100px;
$sideNavWidthCollapsed: 80px;
$sharedHeaderHeight: 80px;
$sideNavWidthExpanded: 200px;
$pageGutterSize: 156px;
$smallUiComponentHeight: 56px;

//BUTTONS
$largeButtonHeight: 64px;
$ctaButtonHeight: 48px;
$buttonHeight: 40px;
$ctaButtonBorderRadius: 24px;
$midRadius: 16px;
$largeRadius: 40px;
$altBtnBorderRadius: 8px;

// TODO Talk to Vitalli about having all svgs render out to the same size
$largeIconSize: 24px;
$defaultHeightBtnIconSize: 24px;
$smallBtnHeightIconSize: 20px;

//Gutters
$rightDashGutterSize: 20px;

//OVERLAY BACKGROUND
$overlayColor: rgba(237, 246, 248);
// Issue with color from designs is it's partially opaque - Verify if that's what we want.
// $overlayColor: rgba(237, 246, 248, 0.8);

//MATERIAL UI COLORS
$primaryColor20: #f8fbfc;
// $primaryColor50: #e3f7fa;
// TODO Find out if this affects other components as this variable changed when adding select poll component
$primaryColor50: #edf6f8;
$primaryColor200: #d9e9ec;
// TODO Discuss with designs because we have the same color as 400 and 500
$primaryColor400: #4d9bac;
$primaryColor500: #4d9bac;
$primaryColor600: #55adbe;
$primaryColor700: #326570;
$primaryColor800: #264c54;
$primaryColor900: #1c393f;

$surfacePrimary: #f3f9fa;

/** Oddly in exported svgs but doesn't map to color system */
$primaryColorLight: #72b1be;

//Secondary
///TODO All of these colors have been deleted from the style guide, not clear how we proceed with these colors are they're currently used in the app
$secondaryColor50: #f4f9eb;
$secondaryColor400: #8cb936;
/** This has been changed to secondary 600 in designs TOOD clarify what colors belong in style guide as this is used in component based design but missing in style guide */
$secondaryColor500: #93c238;
$secondaryColor700: #628125;

$secondaryColor20: #fbfdf7;

//Dark Secondary
// TODO discuss duplicate color with different name in design system
$darkSecondaryColor300: #3e5218;
$secondaryColor600: #3e5218;

//Tertiary
$tertiaryColor20: #f5f7f9;
$tertiaryColor400: #204779;
$tertiaryColor600: #0e2036;

//From designs
$owlDarkBlue: #153e72;
// TODO Rename primary, secondary and tertiary as it interferrs with material ui

//FROM FIGMA
$primary100: #ebf4f6;
$primary150: #d9e9ec;
$primary200: #baebf3;
$primary400: #4d9bac;
$primary500: #55acbe;
// TODO Resolve conflict in design system new primary 600 is introduced in user types select component
$primary600: #3f808d;
$newPrimary600: #22454c;

//GREEN
$greenStatus: #779d2d;

//WARN
$warn: #cc3333;
$warn20: #fdf7f7;
$warn50: #faebeb;
$warn70: #fcf7e6;
$warn100: #f5d6d6;
$warn200: #ffdea3;
$warn400: #dd7777;
$warn600: #65530d;
$warn700: #882222;
$warn750: #5b1717;
$warn900: #441111;

$error100: #faeded;
/** First use in map config*/
$error400: #cc3333;

//YELLOW
$yellow: #ffd700;
$yellow20: #fffdf5;
$yellow50: #fffbe5;
$yellow200: #fff2aa;
$yellow600: #d4b300;
$yellow700: #aa8f00;
$yellow900: #4d4000;
$yellowStatus: #c18518;

//NEUTRALS
$neutralWhite: #ffffff;
$neutrals20: #fafafa;
$neutrals50: #f2f2f2;
$neutrals100: #e6e6e6;
$neutrals200: #cccccc;
$neutrals300: #bfbfbf;
$neutrals400: #b3b3b3;
$neutrals500: #999999;
$neutrals550: #999999;
$neutrals600: #7f7f7f;
$neutrals650: #808080;
$neutrals700: #4d4d4d;
$neutrals800: #333333;
$neutrals900: #1a1a1a;
$neutrals1000: #0d0d0d;
$neutralsBlack: #17191c;
$neutralsGray: #737d8c;
$neutralsPopupGray: #0000001a;

//TEXT
$textPrimary: #262729;
$textSecondary: #4b4c4d;

//DISABLED
$textDisabled: #989899;

//BRAND
$brandText: #22454c;

//RGBA Section
$mainUiLabelColor: rgba(26, 26, 26, 0.85);
$snackBarBackgroundColor: rgba(51, 51, 51, 0.85);
$pollSummaryBackgroundColor: rgba(174, 210, 217, 0.4);

//ICONS
$chatUiIconSize: 32px;

// BREAKPOINT CLASSNAMES
//TODO Put into use when we have mobile and tablet designs for browser app
// From src\app\directives\viewport-classes.directive.model.ts but here for scss variable ref - an unfortunate violate of DRY but okay for this benefit of reuse
$XS: '--xtra-small';
$S: '--small';
$M: '--medium';
$L: '--large';

//BORDERS
%sideNaveBorderRadius {
  border-radius: 0px 32px 32px 0px;
}
%activeMapAreaBorder {
  border: 1.5px solid $neutrals900;
}

//BOX SHADOW
%xsmBoxShadow {
  box-shadow: 0px 1px 2px rgba(21, 62, 114, 0.05);
}

%smBoxShadow {
  box-shadow: 0px 1px 3px rgba(21, 62, 114, 0.1), 0px 1px 2px rgba(21, 62, 114, 0.06);
}

%mdBoxShadow {
  box-shadow: 0px 4px 8px -2px rgba(21, 62, 114, 0.1), 0px 2px 4px -2px rgba(21, 62, 114, 0.06);
}

%lgBoxShadow {
  box-shadow: 0px 12px 16px -4px rgba(21, 62, 114, 0.08), 0px 4px 6px -2px rgba(21, 62, 114, 0.03);
}

%xlBoxShadow {
  box-shadow: 0px 20px 24px -4px rgba(21, 62, 114, 0.08), 0px 8px 8px -4px rgba(21, 62, 114, 0.03);
}

%xl2BoxShadow {
  box-shadow: 0px 24px 48px -12px rgba(21, 62, 114, 0.18);
}

%xl3BoxShadow {
  box-shadow: 0px 32px 64px -12px rgba(21, 62, 114, 0.14);
}

%popupBoxShadow {
  box-shadow: 0px 2px 4px -2px rgba(38, 76, 84, 0.2), 0px 4px 8px -2px rgba(38, 76, 84, 0.2);
}

//RESPONSIVE LAYOUTS
$XS: '--xtra-small';
$S: '--small';
$M: '--medium';
$L: '--large';

//TRANSITIONS
$sideNavTransitionDuration: 0.4s;

//SEPERATOR
%listDivider {
  margin: 0.5rem 0;
  background-color: $neutrals50;
  height: 2px;
  width: 100%;
  &:last-of-type {
    display: none;
  }
}

//MATERIAL UI OVERRIDES
$menuOffset: 12px;
// $primaryColor500 - rgba for opacity
%publicPageBackgroundGradient {
  background: linear-gradient(180deg, rgba(77, 155, 172, 0) 50%, rgba(77, 155, 172, 0.2) 100%),
    linear-gradient(0deg, $surfacePrimary, $surfacePrimary);
}
