/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './variables.scss';
@import './typography.scss';

//from owl image fed into https://m2.material.io/inline-tools/color/
$customPrimary: (
  50: $primaryColor50,
  100: #baebf3,
  200: #91deeb,
  300: #6fd1e1,
  400: #5fc7d9,
  500: #5bbdd1,
  600: $primaryColor600,
  //#55adbe
  700: #4d97a4,
  800: #47838c,
  900: #3c6062,
  A100: $primaryColor600,
  A200: $primaryColor600,
  A400: $primaryColor600,
  A700: $primaryColor600,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white
  )
);
$customSecondary: (
  50: #f2f8e7,
  100: #dfecc4,
  200: #cae09e,
  300: #b5d477,
  400: #a4cb58,
  500: #94c238,
  600: #84b230,
  700: #6f9e26,
  800: #5b8b1d,
  900: #366909,
  A100: $secondaryColor500,
  A200: $secondaryColor500,
  A400: $secondaryColor500,
  A700: $secondaryColor500,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$owl-web-primary: mat.define-palette($customPrimary);
$owl-web-accent: mat.define-palette($customSecondary);

// The warn palette is optional (defaults to red).
$owl-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$owl-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $owl-web-primary,
      accent: $owl-web-accent,
      warn: $owl-web-warn
    )
  )
);
@include mat.typography-hierarchy($owl-main-custom-typography);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($owl-web-theme);

//BROWSER OVERRIDES
body {
  margin: 0;
}

//temp map overrides
.ol-overlaycontainer-stopevent {
  display: flex;
}

html,
body {
  height: 100%;
  // Remove default black border as focus style from app since our design doesn't include focus styles
  .esri-view .esri-view-surface--inset-outline:focus::after {
    outline: none;
  }
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
// Here because map is used on multiple pages
.map-container {
  height: 100vh;
  width: 100%;
}
//Global Modal Border Radius Override
.custom-modal-border-radius .mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 16px;
}
.globally-disable-scroll {
  overflow: hidden;
}
